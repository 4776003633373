import { START_REQUESTING, STOP_REQUESTING } from '../../types/ui/form.types';

function startRequesting() {
    return { type: START_REQUESTING };
}

function stopRequesting() {
    return { type: STOP_REQUESTING };
}

export const executeAnyTypeOfRequest = (action, onSuccess) => {
    return (dispatch) => {
        dispatch(startRequesting());
        return action()
            .then(({ data }) => {
                dispatch(stopRequesting());
                if (onSuccess) onSuccess();
                return data;
            })
            .catch((error) => {
                console.error({ error });
                dispatch(stopRequesting());
            });
    };
};

export default { startRequesting, stopRequesting, executeAnyTypeOfRequest };
