import { v1 as uuidV1 } from 'uuid';
import { NOT_SPECIFIED } from './enums';

function formatPrice(price = 0, options = { withMinusSymbol: false, withEuroSymbol: true, isTTC: false }) {
    const roundedPrice = roundToTwoDecimal(price);
    const priceParts = String(roundedPrice).split('.');

    const integerPart = extractAndFormatIntegerPart(priceParts);
    const decimalPart = extractAndFormatDecimalPart(priceParts);
    const pricePrefixes = [];
    if (options.withMinusSymbol) pricePrefixes.push('-');

    return `${pricePrefixes.join('')} ${integerPart}${decimalPart} ${options.withEuroSymbol ? '€' : ''} ${options.isTTC ? 'TTC' : ''}`.trim();
}

function formatVolume(volume = 0, options = { withUnit: true }) {
    const roundedVolume = roundToTwoDecimal(volume);
    const volumeParts = String(roundedVolume).split('.');

    const integerPart = extractAndFormatIntegerPart(volumeParts);
    const decimalPart = extractAndFormatDecimalPart(volumeParts);
    const volumeSuffixes = [];
    if (options.withUnit) volumeSuffixes.push('kWh cumac');

    return `${integerPart}${decimalPart} ${volumeSuffixes.join('')}`.trim();
}

function roundToTwoDecimal(value) {
    return value ? Math.round((value + Number.EPSILON) * 100) / 100 : 0;
}

function extractAndFormatIntegerPart(parts) {
    let [integerPart] = parts;

    if (integerPart.length > 3) {
        const reversedIntegerPart = reverseString(integerPart);
        const threeDigitsGroups = reversedIntegerPart.match(/\d{3}/g);
        const restWithoutThreeDigitsGroups = reversedIntegerPart.substring(threeDigitsGroups.join('').length);
        integerPart = reverseString(restWithoutThreeDigitsGroups);

        return threeDigitsGroups.reverse().reduce((acc, element) => acc + ` ${reverseString(element)}`, integerPart);
    }

    return integerPart;
}

function extractAndFormatDecimalPart(parts) {
    return parts.length > 1 ? `.${parts[1]}` : '';
}

function reverseString(value = '') {
    return value.split('').reverse().join('');
}

function getOperationsNames(operations = []) {
    return operations.map(({ operationSheetName }) => ({
        key: uuidV1(),
        name: formatOperationSheetName(operationSheetName),
    }));
}

function formatOperationSheetName(operationSheetName) {
    let [operationName, version] = operationSheetName.replace(/_/g, '-').split('V-');

    let formattedSuffix = '';
    if (version.includes('-CDP')) {
        version = version.replace('-CDP', '');
        formattedSuffix += ' - (Coup de pouce)';
    }
    version = version.replace('-', '.');

    return `${operationName} (v. ${version})${formattedSuffix}`;
}

export const getValueOrTheSpecifiedValueIfUndefined = (value, specifiedUndefinedValue = NOT_SPECIFIED) => value || specifiedUndefinedValue;

export default {
    formatPrice,
    formatVolume,
    getOperationsNames,
};
